import ENV_CONFIG from '@config/environment/index';
import mixpanel from "mixpanel-browser";
import { getPubnubChannelName, handlePubnubData, isUserloggedIn, getMediaReadyChannelName, handleMediaReadyPubnubData } from "@utils/common";
import Pubnub from "@utils/pubnub";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { SESSION_STORAGE } from '@utils/constants';

const mixPanelLib = () => {
    if (mixpanel) {
        mixpanel.init(ENV_CONFIG.MIXPANEL.KEY, {
            debug: ENV_CONFIG.MIXPANEL.DEBUG,
            opt_out_tracking_by_default: false,
            ignore_dnt: true
        });
        mixpanel.reset();
    }
};

export const pubnub = (login = false, mediaReadyPubnub = false) => {
    let loggedInChannelName = getPubnubChannelName(),
        mediaReadyChannelName = getMediaReadyChannelName(),
        channelName = mediaReadyPubnub ? mediaReadyChannelName : loggedInChannelName;
    let isBingeTrialFlow = !!JSON.parse(sessionStorage.getItem(SESSION_STORAGE.IS_BINGE_TRIAL_FLOW)); // Pubnub Flow is restricted for Binge Trial Flow

    if ((loggedInChannelName && isUserloggedIn() && !isBingeTrialFlow) || (mediaReadyChannelName && mediaReadyPubnub)) {
        Pubnub(channelName, (data) => {
            mediaReadyPubnub ? handleMediaReadyPubnubData(data, 'from push for media ready pubnub') : handlePubnubData(data, 'from push for app pubnub')
        }, login, mediaReadyPubnub);
    }
}

export const firebaseAnalyticsInfo = () => {
    // Initialize Firebase
    const app = initializeApp(ENV_CONFIG.FIREBASE_CONFIG);
    getAnalytics(app);
}

export default () => {
    mixPanelLib();
    pubnub();
    firebaseAnalyticsInfo();
};
