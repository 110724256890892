import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import { isEmpty } from "lodash";

import { hideFooter, hideHeader, hideMainLoader, showMainLoader, showMainLoaderImmediate } from "@src/action";
import { deleteKey, getKey, setKey } from "@utils/storage";
import { errorForAPIFailure, checkCurrentSubscription, checkIsFirstSubscription, handlePaymentRetry } from '@containers/Subscription/APIs/subscriptionCommon';
import { URL } from "@constants/routeConstants";
import { closePopup, openPopup } from '@common/Modal/action';
import {
    safeNavigation,
    trackModifyPackSuccess,
    getPaymentType,
    getAnalyticsSource,
    getSubscriptionJourneySource,
    trackMixpanelSubscriptionSuccess,
    trackMixpanelSubscriptionFailed,
    callLogOut,
    isUserloggedIn,
    getPackModificationType,
    trackGoogleConversionSuccess,
    getMixpanelSubscriptionAnalyticsData,
    getPaymentTypeValue,
    time_convert,
} from "@utils/common";
import { MODALS } from "@common/Modal/constants";
import { SUBSCRIPTION_EXTERNAL_SOURCES, LOCALSTORAGE, WEB_SMALL_PAYMENT_SOURCE } from "@constants";
import { fetchConfig } from "@components/Header/APIs/actions";

import { getOpelResponse, setPaymentProgressState } from "../APIs/action";
import PaymentSuccess from '../PaymentSuccess';
import {
    ERROR_HANDLING_VERBIAGES,
    fetchDetailsFromURL,
    METHOD_TYPE,
    OPEL_STATUS,
    PENDING_PAYMNET_ERROR_CODES,
    FAILED_PAYMNET_ERROR_CODES,
    PAYMENT_CODE_POLLING_ELIGIBLE,
    PAYMENT_PROGRESS_TIME,
    USER_ACTION,
    DTH_PAYMENT_STATUS,
} from '../APIs/constants';
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import appsFlyerConfig from "@utils/appsFlyer";
import APPSFLYER from "@utils/constants/appsFlyer";
import { getCurrentSubscriptionInfo } from "@containers/Subscription/APIs/action";
import { PAYMENT_METHOD } from "../constant";
import Loader from "@common/Loader";
import trackEvent from "@utils/trackEvent";
import dataLayerConfig from "@utils/dataLayer";
import DATALAYER from "@utils/constants/dataLayer";
import FIREBASE from "@utils/constants/firebase";
import { SUBSCRIPTION_STATUS } from "@containers/Subscription/APIs/constant"
import Button from "@common/Buttons";
import { WALLET_ERROR_CODE } from "@containers/SubscriptionPayment/APIs/constants";

import './style.scss';

class SubscriptionPaymentHandler extends Component {
    constructor(props) {
        super(props);
        let isPaymentStatusTracked = JSON.parse(getKey(LOCALSTORAGE.IS_PAYMENT_STATUS_TRACKED));
        this.state = {
            isPaymentSuccessful: false,
            isPaymentInProgress: true,
            paymentProgreessTime: get(this.props?.configResponse?.paymentProcessingVerbiage, 'paymentProcessingTime', PAYMENT_PROGRESS_TIME),
            isPaymentFail: false,
            isPaymentStatusTracked,
            paymentStatus: '',
        };
        this.paymentTimeout = '';
        this.paymentStatusSuccessReceived = false;

        // isPaymentStatusTracked is key to track if for a particular orderId page has been already visited or not, 
        // if visited then mixpanel event for same mixpanel ID should not trigger - TPB-327
    }

    componentDidMount = async () => {
        let { history, configResponse, fetchConfig } = this.props;
        const transactionId = getKey(LOCALSTORAGE.TRANSACTION_ID);
        let ssoLoginJourney = JSON.parse(getKey(LOCALSTORAGE.SSO_LOGIN_JOURNEY)) === true;
        let isPaymentNotRequest = history?.location?.state?.isPaymentNotRequest;
        isEmpty(configResponse) && await fetchConfig(true);
        ssoLoginJourney && deleteKey(LOCALSTORAGE.SSO_LOGIN_JOURNEY);
        this.state.isPaymentInProgress && this.startProgressTimer();
        isPaymentNotRequest
            ? this.handleTickTickFDORequest()
            : !isEmpty(transactionId)
                ? this.handleCallbackStatus()
                : safeNavigation(history, `${URL.DEFAULT}`);
    };

    componentDidUpdate = async (prevProps) => {
        if (this.props.paymentStatusFromPubnub !== prevProps.paymentStatusFromPubnub) {
            this.props.paymentStatusFromPubnub === OPEL_STATUS.SUCCESS && this.subscribeOpelStatus(false, true);
        }
    };

    componentWillUnmount = () => {
        clearTimeout(this.paymentTimeout);
    };

    /**
     * @function handleTimerProgress - calculate the time difference in milliseconds between our current date and end date and set in the state paymentProgreessTime.
     */
    handleTimerProgress = () => {
        this.setState((prevState) => ({
            paymentProgreessTime: prevState.paymentProgreessTime - 1,
        }));
    };

    getTransactionStatus = (paymentStatus) => {
        if (paymentStatus === MIXPANEL.VALUE.FAILURE) {
            return MIXPANEL.VALUE.FAILED;
        } else if (paymentStatus === MIXPANEL.VALUE.SUCCESS) {
            return MIXPANEL.VALUE.COMPLETED;
        } else {
            return MIXPANEL.VALUE.PENDING;
        }
    };

    trackPaymentEvent = async (instructions = "", paymentStatus = "", isRetryFlow = false) => {
        const { currentSubscription, paymentMethod, balanceAmount, opelResponse } = this.props;
        const walletName = opelResponse ? opelResponse.data?.paymentMethod : currentSubscription?.data?.paymentMethod;
        const isTSWallet = paymentMethod === PAYMENT_METHOD.TS_WALLET;
        const subscriptionDetails = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK));
        const transactionId = getKey(LOCALSTORAGE.TRANSACTION_ID);
        const pgResponseCode = fetchDetailsFromURL(window.location.href, 'status');
        const userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
        const previousSubscription = JSON.parse(getKey(LOCALSTORAGE.PREVIOUS_SUBSCRIPTION_DETAILS));


        let data = {
            [MIXPANEL.PARAMETER.SUBSCRIBER_BALANCE]: isTSWallet ? get(balanceAmount, "balance") : "",
            [MIXPANEL.PARAMETER.TRANSACTION_STATUS]: this.getTransactionStatus(paymentStatus),
            [MIXPANEL.PARAMETER.CARD_TYPE]: "",
            [MIXPANEL.PARAMETER.BANK_NAME]: "",
            [MIXPANEL.PARAMETER.WALLET_NAME]: walletName,
            [MIXPANEL.PARAMETER.TRANSACTION_ID]: transactionId,
            [MIXPANEL.PARAMETER.CARD_NAME]: "",
            [MIXPANEL.PARAMETER.AMOUNT]: subscriptionDetails?.amountValue || MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE,
            [MIXPANEL.PARAMETER.FAILURE_REASON]: instructions,
            [MIXPANEL.PARAMETER.RENEWAL_MODE]: isTSWallet ? MIXPANEL.VALUE.TP_WALLET : MIXPANEL.VALUE.PG,
            [MIXPANEL.PARAMETER.PAYMENT_STATUS]: paymentStatus,
            [MIXPANEL.PARAMETER.PG_RESPONSE_CODE]: pgResponseCode?.toUpperCase(),
            [MIXPANEL.PARAMETER.QR_SOURCE]: userInfo?.qrSource,
        };
        let paymentJourneyProperty = getMixpanelSubscriptionAnalyticsData(previousSubscription, " ", MIXPANEL)

        let paymentProperty = {
            ...paymentJourneyProperty,
            [MIXPANEL.PARAMETER.PAYMENT_MODE]: isTSWallet ? MIXPANEL.VALUE.TP_WALLET : MIXPANEL.VALUE.PG,
            [MIXPANEL.PARAMETER.PAYMENT_TYPE]: getPaymentTypeValue(),
            [MIXPANEL.PARAMETER.PAYMENT_METHOD]: isTSWallet ? MIXPANEL.VALUE.TP_WALLET : MIXPANEL.VALUE.PG,
           // [MIXPANEL.PARAMETER.PAYMENT_INSTRUMENT]: walletName, commented for TPB-721
        }

        let paymentdata = { ...data, ...paymentProperty };
        if(isRetryFlow){
            return paymentdata;
        }
        else{
            this.trackMixpanelOnPaymentExitFlow(paymentStatus, paymentJourneyProperty);
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.PAYMENT, paymentdata);
        }
    }

    handleTSWalletPayment = async () => {
        const { paymentThroughTSRes } = this.props;
        let isErrorExistAtBE = WALLET_ERROR_CODE.includes(paymentThroughTSRes?.code);
        let tpWalletChared = this.props?.location?.state?.walletStatus === DTH_PAYMENT_STATUS.CHARGED;

        if (paymentThroughTSRes?.code === 0 || isErrorExistAtBE || tpWalletChared) {
            this.handleStatusCall();
        } 
       else {
            this.showErrorModal(false);
        }
    };

    handleCallbackStatus = async () => {
        if (this.props.paymentMethod === PAYMENT_METHOD.PG) {
            this.handleStatusCall();
        } else {
            await this.handleTSWalletPayment();
        }
    };

    getExitStatus = () => {
        let status = fetchDetailsFromURL(window.location.href, 'status'),
            isStatusCategoryPending = PENDING_PAYMNET_ERROR_CODES.includes(status?.toUpperCase()),
            isStatusCategoryFailed = FAILED_PAYMNET_ERROR_CODES.includes(status?.toUpperCase());

        if (status?.toUpperCase() === OPEL_STATUS.CHARGED) {
            return MIXPANEL.VALUE.SUCCESS;
        } else if (isStatusCategoryPending) {
            return MIXPANEL.VALUE.PENDING;
        } else if (isStatusCategoryFailed) {
            return MIXPANEL.VALUE.FAILURE;
        }
    };

    trackMixpanelOnPaymentExitFlow = (paymentStatus, paymentJourneyProperty) => {
        let exitStatus = this.getExitStatus(),
            isTSWallet = this.props.paymentMethod === PAYMENT_METHOD.TS_WALLET,
            pgResponseCode = fetchDetailsFromURL(window.location.href, 'status');

        mixPanelConfig.trackEvent(MIXPANEL.EVENT.PAYMENT_FLOW_EXIT,
            {
                [MIXPANEL.PARAMETER.EXIT_STATUS]: isTSWallet ? paymentStatus : exitStatus,
                [MIXPANEL.PARAMETER.PAYMENT_METHOD]: isTSWallet ? MIXPANEL.VALUE.TP_WALLET : MIXPANEL.VALUE.PG,
                [MIXPANEL.PARAMETER.PAYMENT_STATUS]: isTSWallet ? paymentStatus : exitStatus,
                [MIXPANEL.PARAMETER.PG_RESPONSE_CODE]: isTSWallet ? paymentStatus : pgResponseCode?.toUpperCase(),
                ...paymentJourneyProperty,
            });
    };

    showErrorModal = (trackEvent = true) => {
        let status = fetchDetailsFromURL(window.location.href, 'status'),
            isStatusCategoryPending = PENDING_PAYMNET_ERROR_CODES.includes(status?.toUpperCase()),
            errorMsgFromBacked = JSON.parse(getKey(LOCALSTORAGE.PAYMENT_ERROR_STATUS_VERBIAGE)),
            pendingStateError = get(errorMsgFromBacked, 'transactionPendingVerbiage', ERROR_HANDLING_VERBIAGES.PENDING_STATE_ERROR),
            failedStateError = get(errorMsgFromBacked, 'paymentFailureVerbiage', ERROR_HANDLING_VERBIAGES.PAYMENT_FAILED_ERROR),
            instructions = isStatusCategoryPending ? pendingStateError : failedStateError,
            primaryButtonText = isStatusCategoryPending ? ERROR_HANDLING_VERBIAGES.CLOSE_BTN : ERROR_HANDLING_VERBIAGES.TRY_AGAIN,
            secondaryButtonLinkText = !isStatusCategoryPending && ERROR_HANDLING_VERBIAGES.CLOSE_BTN,
            sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE;

        clearTimeout(this.paymentTimeout);
        clearInterval(this.intervalId);
        this.setState({
            isPaymentInProgress: false,
        });

        this.props.openPopup(MODALS.ALERT_MODAL, {
            modalClass: 'alert-modal error-state-modal',
            errorIcon: 'icon-alert-upd',
            hideCloseIcon: true,
            headingMessage: ERROR_HANDLING_VERBIAGES.HEADING,
            instructions,
            primaryButtonText: sourceIsMSales ? "" : primaryButtonText,
            secondaryButtonLinkText: sourceIsMSales ? "" : secondaryButtonLinkText,
            primaryButtonAction: () => {
                this.handleApiFailure(!isStatusCategoryPending)
            },
            secondaryButtonAction: this.handleApiFailure,
        });

        let { paymentMethod, paymentThroughTSRes, opelResponse } = this.props,
            failureErrorMsg = paymentMethod === PAYMENT_METHOD.PG ?  opelResponse?.data?.errorResponse : paymentThroughTSRes?.message;

            trackEvent && !this.state.isPaymentStatusTracked && this.trackSubscriptionFailed(failureErrorMsg);
            !trackEvent && this.trackMixpanelOnPaymentExitFlow(MIXPANEL.VALUE.FAILURE); // in case when SUBSCRIBE-FAILED is not tracked PAYMENT-EXIT should be tracked.
    };

    trackSubscriptionFailed = async (instructions) => {
        await this.trackPaymentEvent(instructions, MIXPANEL.VALUE.FAILURE);
        trackMixpanelSubscriptionFailed(instructions);
        let appsFlyer = this.getAnalyticsData(APPSFLYER, APPSFLYER.VALUE.PG,)
        appsFlyer[`${APPSFLYER.PARAMETER.TYPE}`] = APPSFLYER.VALUE.PAID;
        appsFlyer[`${APPSFLYER.PARAMETER.REASON}`] = instructions;
        appsFlyerConfig.trackEvent(APPSFLYER.EVENT.SUBSCRIBE_FAILED, appsFlyer)
        const firebaseData = this.getFirebaseAnalyticsData(FIREBASE.EVENT.SUBSCRIBE_FAILED);
        trackEvent.subscriptionFailed({ ...firebaseData, [FIREBASE.PARAMETER.REASON]: instructions })
        this.trackRetryEvent(MIXPANEL.EVENT.PAYMENT_RETRY_POPUP);
    }

    getFirebaseSubscriptionType = () => {
        const previousSubscription = JSON.parse(getKey(LOCALSTORAGE.PREVIOUS_SUBSCRIPTION_DETAILS));
        if (!previousSubscription) {
            return FIREBASE.VALUE.NEW;
        }
        if (previousSubscription?.subscriptionStatus === "ACTIVE") {
            return FIREBASE.VALUE.MODIFY;
        }
        return FIREBASE.VALUE.REPEAT

    }

    handleApiFailure = (isTryAgain = false) => {
        let { closePopup, history } = this.props,
            isPaymentFromSubscription = getKey(LOCALSTORAGE.IS_PAYMENT_FROM_SUBSCRIPTION),
            isPaymentFromDiscount = getKey(LOCALSTORAGE.IS_PAYMENT_FROM_DISCOUNT),
            paymentInitiatePath = isPaymentFromDiscount ? `/${URL.SUBSCRIPTION_DISCOUNT}` : `/${URL.SUBSCRIPTION}`,
            sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE;
        closePopup();
        deleteKey(LOCALSTORAGE.TRANSACTION_ID);
        deleteKey(LOCALSTORAGE.PAYMENT_STATUS_VERBIAGE);
        deleteKey(LOCALSTORAGE.PAYMENT_ERROR_STATUS_VERBIAGE);
        deleteKey(LOCALSTORAGE.SUBSCRIPTION_CHANGE_TYPE);
        deleteKey(LOCALSTORAGE.IS_PAYMENT_FROM_SUBSCRIPTION);
        deleteKey(LOCALSTORAGE.IS_PAYMENT_FROM_DISCOUNT);
        deleteKey(LOCALSTORAGE.GET_PAYMENT_DETAILS);
        deleteKey(LOCALSTORAGE.PREVIOUS_SUBSCRIPTION_DETAILS);
        deleteKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK);
        deleteKey(LOCALSTORAGE.SUBSCRIPTION_JOURNEY_SOURCE);
        deleteKey(LOCALSTORAGE.CART_ID);
        deleteKey(LOCALSTORAGE.SUBSCRIPTION_SOURCE);

        if (isTryAgain) {
            isPaymentFromSubscription ? safeNavigation(history, paymentInitiatePath) : safeNavigation(history, `${URL.DEFAULT}`);
        } else {
            safeNavigation(history, `${URL.DEFAULT}`)
        }
    }

    handleStartWatching = async () => {
        let { history } = this.props;
        let sourceISMService = getKey(LOCALSTORAGE.SUBSCRIPTION_EXTERNAL_SOURCE) === SUBSCRIPTION_EXTERNAL_SOURCES.M_SERVICE,
            appsFlyerRedirectionUrl = get(this.props.configResponse, 'appsflyerRedirectionLink');

        this.setState({
            isPaymentsuccessful: false,
        });
         
        this.handleDeleteLocalStorageKeys()

        const pi_detail_url = await getKey(LOCALSTORAGE.PI_DETAIL_URL);
        if (sourceISMService) {
            window.open(appsFlyerRedirectionUrl, '_blank');
        }
        else if (!!pi_detail_url) {
            safeNavigation(history, `${pi_detail_url}`)
        } else {
            setKey(LOCALSTORAGE.START_WATCHING_NOW, true )
            safeNavigation(history, `${URL.DEFAULT}`)
        }
        deleteKey(LOCALSTORAGE.PI_DETAIL_URL);
        deleteKey(LOCALSTORAGE.CART_ID);
        dataLayerConfig.trackEvent(DATALAYER.EVENT.SUB_JOURNEY, {
            [DATALAYER.PARAMETER.BUTTON_NAME]: DATALAYER.VALUE.START_WATCHING,
        });
        let isZeroAPPSource = getKey(LOCALSTORAGE.ZERO_APP_SOURCE);
         isZeroAPPSource && mixPanelConfig.trackEvent(MIXPANEL.EVENT.APPS_ADDED_SUCCESS_DONE, {
            [`${MIXPANEL.PARAMETER.SOURCE}`]: isZeroAPPSource
         });
        deleteKey(LOCALSTORAGE.ZERO_APP_SOURCE);
    }
  
    handleDeleteLocalStorageKeys = () => {
        deleteKey(LOCALSTORAGE.TRANSACTION_ID);
        deleteKey(LOCALSTORAGE.PAYMENT_STATUS_VERBIAGE);
        deleteKey(LOCALSTORAGE.PAYMENT_ERROR_STATUS_VERBIAGE);
        deleteKey(LOCALSTORAGE.SUBSCRIPTION_CHANGE_TYPE);
        deleteKey(LOCALSTORAGE.PREVIOUS_SUBSCRIPTION_DETAILS);
        deleteKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK);
        deleteKey(LOCALSTORAGE.SUBSCRIPTION_JOURNEY_SOURCE);
        deleteKey(LOCALSTORAGE.SUBCRIPTION_SOURCE)
    }


    /**
     * @function handleStatusCall - handling of payment staus API polling
     */
    handleStatusCall = () => {
        let { configResponse } = this.props,
            paymnetCallBackArray = get(configResponse, 'paymentCallbackArrayInfo.paymentStatusApiArray', [10000, 20000]);

        for (let i = 0; i < paymnetCallBackArray.length; i++) {
            let isLastIteration = (i + 1) === paymnetCallBackArray.length,
                { opelResponse } = this.props,
                opelPaymentStatus = get(opelResponse, 'data.paymentStatus')?.toUpperCase();

            if ((opelPaymentStatus !== OPEL_STATUS.SUCCESS || opelPaymentStatus !== OPEL_STATUS.FAILED) && !this.paymentStatusSuccessReceived) {
                this.props?.setPaymentProgressState(true);
                this.paymentTimeout = setTimeout(async () => {
                    if (this.paymentStatusSuccessReceived) {
                        this.props?.setPaymentProgressState(false);
                        clearTimeout(this.paymentTimeout);
                        return;
                    }
                    await this.getOpelStatusResponse();
                    this.subscribeOpelStatus(isLastIteration);
                }, paymnetCallBackArray[i]);
            }
        }
    };


    /**
     * @function subscribeOpelStatus - handling of OPEL payment status received in API or PUBNUB resp
     * @param {*} isLastIteration - boolean to handle inprogress scenario in case of last iteration
     * @param {*} isSuccessFromPubnub - boolean to handle success handlimng in case of pubnub push or retry code -19035
     * @returns 
     */
    subscribeOpelStatus = async (isLastIteration = false, isSuccessFromPubnub = false) => {
        let { opelResponse } = this.props;
        let paymentStatus = get(opelResponse, 'data.paymentStatus')?.toUpperCase();
        let { isPaymentStatusTracked } = this.state;

        if ((get(opelResponse, 'code') === 0 && !isEmpty(get(opelResponse, 'data')) || isSuccessFromPubnub)) {

            setKey(LOCALSTORAGE.IS_PAYMENT_STATUS_TRACKED, JSON.stringify(true))

            if (paymentStatus === OPEL_STATUS.SUCCESS || isSuccessFromPubnub) {
                if (this.paymentStatusSuccessReceived) {
                    return;
                }
                await this.props.getCurrentSubscriptionInfo();
                this.paymentStatusSuccessReceived = true;
                !isPaymentStatusTracked && await this.trackPaymentEvent("", MIXPANEL.VALUE.SUCCESS);
                !isPaymentStatusTracked && await this.trackAnalyticsSubscriptionEvent();
                this.setState({
                    isPaymentSuccessful: true,
                    isPaymentInProgress: false,
                    isPaymentFail: false,
                    paymentStatus: OPEL_STATUS.SUCCESS,
                }, async () => {
                    clearTimeout(this.paymentTimeout);
                    clearInterval(this.intervalId);
                    await this.handleMSalesLogoutJourney();
                });
            } else if (paymentStatus === OPEL_STATUS.INPROGRESS) {
                this.setState({ isPaymentInProgress: true });
                if (isLastIteration) {
                    this.setState({
                        isPaymentInProgress: false,
                        isPaymentFail: true,
                        paymentStatus: OPEL_STATUS.INPROGRESS,
                    });
                    clearTimeout(this.paymentTimeout);
                    this.getPaymentPendingError();
                    this.trackRetryEvent(MIXPANEL.EVENT.PAYMENT_RETRY_POPUP);
                }
            } else if (paymentStatus === OPEL_STATUS.FAILED) {
                this.setState({ 
                    isPaymentInProgress: false, 
                    isPaymentFail: true,
                    paymentStatus: OPEL_STATUS.FAILED,
                 });
                let errorMsgFromBacked = JSON.parse(getKey(LOCALSTORAGE.PAYMENT_ERROR_STATUS_VERBIAGE)),
                    instructions = get(errorMsgFromBacked, 'paymentFailedTitle', ERROR_HANDLING_VERBIAGES.PAYMENT_FAILED);
                this.trackSubscriptionFailed(instructions);
                clearTimeout(this.paymentTimeout);
                clearInterval(this.intervalId);
            }
        } else {
            clearTimeout(this.paymentTimeout);
            (this.props.paymentStatusFromPubnub !== OPEL_STATUS.SUCCESS) && !this.state.isPaymentInProgress && errorForAPIFailure(opelResponse, this.handleApiFailure);
            (this.props.paymentStatusFromPubnub !== OPEL_STATUS.SUCCESS) && await this.handleMSalesLogoutJourney();
        }
    };

    handleMSalesLogoutJourney = async () => {
        if (this.isFtvUpsellEnable()){
           return
        }
        await this.mSalesLogout()
    }

    mSalesLogout = async () => {
        let sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE;
        let isSilentLogout = JSON.parse(getKey(LOCALSTORAGE.IS_SILENT_LOGOUT));
        if (sourceIsMSales && !isSilentLogout && isUserloggedIn()) {
            await callLogOut(false, this.props.history, false, false, false);
            setKey(LOCALSTORAGE.IS_SILENT_LOGOUT, true);
        }
    };

    trackAnalyticsSubscriptionEvent = async () => {
        const {currentSubscription} = this.props;
        const subscriptionData = currentSubscription?.data;
        let appsFlyerData = this.getAppsFlyerData(this.props.currentSubscription.data);
        let isFirstSubscription = checkIsFirstSubscription();
        let expired = !checkCurrentSubscription(subscriptionData) && subscriptionData?.subscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.DEACTIVE;

        trackMixpanelSubscriptionSuccess(subscriptionData, this.props.paymentMethod);
        trackGoogleConversionSuccess(subscriptionData, this.props.paymentMethod);
        appsFlyerConfig.trackEvent(APPSFLYER.EVENT.PAYMENT, appsFlyerData);
        appsFlyerConfig.trackEvent(APPSFLYER.EVENT.SUBSCRIPTION_SUCCESS, appsFlyerData);
        const firebaseData = this.getFirebaseAnalyticsData();
        trackEvent.subscriptionSuccess(firebaseData);
        if (isFirstSubscription && !expired) {
            appsFlyerConfig.trackEvent(APPSFLYER.EVENT.SUBSCRIBE_SUCCESS_NEW, appsFlyerData)
            trackEvent.subscriptionSuccessNew(firebaseData)
        } else {
            trackModifyPackSuccess(this.props.currentSubscription.data)
        }
    };

    getPaymentPendingError = (showPopup = false) => {
        let instructions = ERROR_HANDLING_VERBIAGES.PAYMENT_CONFIRMATION_PENDING_ERROR,
            sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE;
            // payment pending popup is removed for normal flow as we have to show payment retry screen for that cases
            showPopup && this.props.openPopup(MODALS.ALERT_MODAL, {   
            modalClass: 'alert-modal error-state-modal',
            errorIcon: 'icon-alert-upd',
            hideCloseIcon: true,
            headingMessage: ERROR_HANDLING_VERBIAGES.HEADING_PENDING,
            instructions: instructions,
            primaryButtonText: sourceIsMSales ? "" : ERROR_HANDLING_VERBIAGES.CLOSE_BTN,
            primaryButtonAction: this.handleApiFailure
        });
        !this.state.isPaymentStatusTracked && this.trackPaymentEvent(instructions, MIXPANEL.VALUE.PENDING);
    };

    getSource = () => {
        let subscriptionChangeType = getKey(LOCALSTORAGE.SUBSCRIPTION_CHANGE_TYPE);
        let piPage = getKey(LOCALSTORAGE.IS_SUBSCRIPTION_FROM_PI) || "";
        if (piPage) {
            return MIXPANEL.VALUE.PI_PAGE
        }
        if (subscriptionChangeType === METHOD_TYPE.ADD_SUBSCRIPTION) {
            return MIXPANEL.VALUE.SUBSCRIBE
        } else {
            return MIXPANEL.VALUE.CHANGE_PLAN
        }
    };

    getAnalyticsData = (analytics = MIXPANEL, paymentMethod,) => {
        let subscriptionDetails = JSON.parse(getKey(LOCALSTORAGE.GET_PAYMENT_DETAILS))
        let path = getKey(LOCALSTORAGE.IS_PAYMENT_FROM_SUBSCRIPTION) || "";
        return {
            [`${analytics.PARAMETER.PACK_NAME}`]: subscriptionDetails?.packName || "",
            [`${analytics.PARAMETER.PAYMENT_TYPE}`]: getPaymentType(this.props.currentSubscription?.data?.paymentMode),
            [`${analytics.PARAMETER.PAYMENT_METHOD}`]: paymentMethod,

        };
    };

    getAppsFlyerData = (currentSubscription) => {
        const selectedPack = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK));
        const { paymentMethod } = currentSubscription;
        return {
            [`${APPSFLYER.PARAMETER.SOURCE}`]: getAnalyticsSource(getSubscriptionJourneySource(), APPSFLYER),
            [APPSFLYER.PARAMETER.PACK_PRICE]: selectedPack?.amountValue || '',
            [APPSFLYER.PARAMETER.AF_REVENUE]: this.props.currentSubscription?.data?.amountValue,
            [APPSFLYER.PARAMETER.AF_CURRENCY]: APPSFLYER.VALUE.INR,
            [APPSFLYER.PARAMETER.PACK_ID]: this.props.currentSubscription?.data?.productId,
            [APPSFLYER.PARAMETER.PACK_NAME]: selectedPack?.productName || '',
            [APPSFLYER.PARAMETER.PACK_DURATION]: this.props?.currentSubscription?.data?.packDuration,
            [APPSFLYER.PARAMETER.PROMO_CODE]: this.props?.currentSubscription?.data?.promoCode,
            [APPSFLYER.PARAMETER.PAYMENT_MODE]: paymentMethod,
        };
    };

    getFirebaseAnalyticsData = (event) => {
        const selectedPack = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK));
        const { currentSubscription } = this.props;
        const paymentData = this.props.opelResponse?.data;
        const paymentMethod = event === FIREBASE.EVENT.SUBSCRIBE_FAILED ? this.props.paymentMethod : currentSubscription?.data?.paymentMethod;
        let data = {
            [FIREBASE.PARAMETER.SOURCE]: getAnalyticsSource(getSubscriptionJourneySource(), APPSFLYER),
            [FIREBASE.PARAMETER.PACK_NAME]: selectedPack?.productName || "",
            [FIREBASE.PARAMETER.PACK_PRICE]: selectedPack?.amountValue,
            [FIREBASE.PARAMETER.PACK_ID]: selectedPack?.productId,
            [FIREBASE.PARAMETER.PACK_DURATION]: selectedPack?.packDuration,
            [FIREBASE.PARAMETER.PROMO_CODE]: paymentData?.promoCode || "",
            [FIREBASE.PARAMETER.PACK_TYPE]: FIREBASE.VALUE.PAID,
            [FIREBASE.PARAMETER.PAYMENT_MODE]: paymentMethod,
        };

        if (event === FIREBASE.EVENT.SUBSCRIBE_FAILED) {
            data = {
                ...data,
                [FIREBASE.PARAMETER.SUBSCRIBE_TYPE]: this.getFirebaseSubscriptionType(),
                [FIREBASE.PARAMETER.PACK_MOD_TYPE]: currentSubscription?.data ? getPackModificationType(selectedPack, this.currentSubscription?.data) : FIREBASE.VALUE.FRESH,
            }
        }

        return data;
    };

    /**
     * @function handleNotNowDefault - handling of flow in vcase of subscribe fail scenarios
     */
    handleNotNowDefault = () => {
        let { history } = this.props,
            sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE;

            if(sourceIsMSales){
                this.state.paymentStatus === OPEL_STATUS.INPROGRESS ? this.getPaymentPendingError(true) : this.showErrorModal();
                this.setState({
                    isPaymentSuccessful: false,
                    isPaymentInProgress: false,
                    isPaymentFail: false,
                })
                this.handleMSalesLogoutJourney();
            }
            else {
                safeNavigation(history, `${URL.DEFAULT}`);
            }
    };

    /**
     * @function trackRetryEvent - to track retry journey events
     * @param {*} eventName 
     */
    trackRetryEvent = async (eventName) => {
        let isTSWallet = this.props?.paymentMethod === PAYMENT_METHOD.TS_WALLET,
        pgResponseCode = fetchDetailsFromURL(window.location.href, 'status'),
        subscriptionDetails = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK)),
        userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));

        let mixpanelData = getMixpanelSubscriptionAnalyticsData(this.props?.currentSubscription?.data, " ",MIXPANEL, true );
        mixpanelData = {...mixpanelData,
            [MIXPANEL.PARAMETER.PAYMENT_MODE]: isTSWallet ? MIXPANEL.VALUE.TP_WALLET : MIXPANEL.VALUE.PG,
            [MIXPANEL.PARAMETER.PAYMENT_TYPE]: getPaymentTypeValue(),
            [MIXPANEL.PARAMETER.PG_RESPONSE_CODE]:  pgResponseCode?.toUpperCase(),
            [MIXPANEL.PARAMETER.QR_SOURCE]: userInfo?.qrSource,
            [MIXPANEL.PARAMETER.AMOUNT]: subscriptionDetails?.amountValue || MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE,
        }
        mixPanelConfig.trackEvent(eventName, mixpanelData);
    };


    /**
     * @function handleRetry - on click of retry button FE will call payment status API ,
     * if SUCCESS is received then success screen will come and retry process will not be initiated,
     *  otherwise in case of FAILED and INPROGRESS FE will call add/modify API to reinitiate the payment flow
     */
    handleRetryAction = async (userAction) => {
        let { history } = this.props;
        let eventName = (userAction === USER_ACTION.RETRY ?  MIXPANEL.EVENT.PAYMENT_RETRY_PROCEED : MIXPANEL.EVENT.PAYMENT_RETRY_CANCEL);
        this.props?.setPaymentProgressState(false);
        this.trackRetryEvent(eventName);
        let fallbackFunc = userAction === USER_ACTION.RETRY ? handlePaymentRetry(history, this.subscribeOpelStatus, true, false) : this.handleNotNowDefault();
        if(this.state.paymentStatus === OPEL_STATUS.INPROGRESS) {
            let isNotNowRetry =  userAction === USER_ACTION.NOT_NOW
            await this.getOpelStatusResponse(isNotNowRetry);
            let { opelResponse } = this.props,
            opelPaymentStatus = get(opelResponse, 'data.paymentStatus')?.toUpperCase();
            this.setState({paymentStatus: opelPaymentStatus});
            opelPaymentStatus === OPEL_STATUS.SUCCESS ? this.subscribeOpelStatus() : fallbackFunc; 
        }
        else {
            fallbackFunc
        }
    };

    /**
     * @function getOpelStatusResponse - payment status API call 
     */
    getOpelStatusResponse = async (notNowRetry = false) => {
        let { getOpelResponse } = this.props,
            userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)),
            transactionId = getKey(LOCALSTORAGE.TRANSACTION_ID),
            payload = {
                baId: get(userInfo, 'baId'),
                paymentTransaction: transactionId,
                cartId: getKey(LOCALSTORAGE.CART_ID),
                notNowRetry,
            };
        await getOpelResponse(payload);
    };

    /**
    * @function startProgressTimer - make it run every second using the setInterval() method .
    */
        startProgressTimer = () => {
            this.props?.setPaymentProgressState(true);
            this.intervalId = setInterval(() => {
                if (this.state.paymentProgreessTime === 0) {
                    clearInterval(this.intervalId);
                    this.props?.setPaymentProgressState(false);
                    !this.state.isPaymentSuccessful && this.setState({
                        isPaymentInProgress: false,
                        isPaymentFail: true,
                    },() => {
                       // this.trackRetryEvent(MIXPANEL.EVENT.PAYMENT_RETRY_POPUP)
                    })
                } else {
                    this.handleTimerProgress();
                }
            }, 1000);
        };



    isFtvUpsellEnable = () => {
        let selectedPack = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK));
        return !!get(selectedPack, 'ftvUpsell')
    }

    /**
    * @function handleTickTickFDORequest - make it handle the FDO raised scenario in case of tick tick change one app click success.
    */

    handleTickTickFDORequest = async () => {
        let isZeroAPPSource = getKey(LOCALSTORAGE.ZERO_APP_SOURCE);
        isZeroAPPSource ? mixPanelConfig.trackEvent(MIXPANEL.EVENT.APPS_ADDED_SUCCESS_VIEW, {
          [`${MIXPANEL.PARAMETER.SOURCE}`]: isZeroAPPSource
        }) : mixPanelConfig.trackEvent(MIXPANEL.EVENT.PAGE_VIEW, {[MIXPANEL.PARAMETER.PAGE_NAME]: MIXPANEL.VALUE.APP_REPLACEMENT_SUCCESS_PAGE});
        this.setState({
            isPaymentSuccessful: true,
            isPaymentInProgress: false,
        });
        await this.props.getCurrentSubscriptionInfo();
    }

    render() {
        let { isPaymentSuccessful, isPaymentInProgress, paymentProgreessTime, isPaymentFail } = this.state;
        const { paymentMethod, configResponse, currentSubscription } = this.props;
        let paymentProcessingVerbiages = get(configResponse, 'paymentProcessingVerbiages'),
            paymentErrorVerbiagesDTO = get(configResponse, 'paymentErrorVerbiagesDTO');
        let isTickTick = currentSubscription?.data?.flexiPlan;
        return (
            <React.Fragment>
                {isPaymentSuccessful && <PaymentSuccess
                    onStartClick={this.handleStartWatching}
                    paymentStatusVerbiage={JSON.parse(getKey(LOCALSTORAGE.PAYMENT_STATUS_VERBIAGE))}
                    showConfettiEffect={paymentMethod === PAYMENT_METHOD.PG}
                    handleMSalesLogoutJourney={this.mSalesLogout}
                    handleDeleteLocalStorageKeys = {this.handleDeleteLocalStorageKeys}
                    isFtvUpsellEnable={this.isFtvUpsellEnable}
                    isTickTick = {isTickTick}
                />}
                {isPaymentInProgress &&
                    (<div>
                        <div className="payment-progress-wrapper">
                            <Loader alwaysVisible={true} isPaymentProgress={true} />
                            <p className="in-progress-pri">{get(paymentProcessingVerbiages, 'paymentProcessingTitle', ERROR_HANDLING_VERBIAGES.PAYMENT_PROCESSING)}</p>
                            <p className="in-progress-sec">{get(paymentProcessingVerbiages, 'paymentProcessingDesc', ERROR_HANDLING_VERBIAGES.NOT_REFRESH)}</p>
                            <p className="progress-timer">{time_convert(paymentProgreessTime, true, true)}</p>
                        </div>
                    </div>)
                }
                {isPaymentFail &&
                    (<div>
                        <div className="payment-fail-wrapper">
                            <i className="icon-alert-upd" />
                            <div className="fail-pri">{get(paymentErrorVerbiagesDTO, 'paymentFailedTitle', ERROR_HANDLING_VERBIAGES.PAYMENT_FAILED)}</div>
                            <p className="fail-sec" dangerouslySetInnerHTML={{ __html: get(paymentErrorVerbiagesDTO, 'paymentFailedDesc', ERROR_HANDLING_VERBIAGES.PAYMENT_FAILED_STATEMENT) }} />
                            <Button
                                cName={`btn primary-btn`}
                                bType="button"
                                bValue={get(paymentErrorVerbiagesDTO, 'paymentFailedCtaPrimary', ERROR_HANDLING_VERBIAGES.PAYMENT_FAILED_CTA_PRIMARY)}
                                clickHandler={() => this.handleRetryAction(USER_ACTION.RETRY)}
                            />
                            <p className="not-now" onClick={() => this.handleRetryAction(USER_ACTION.NOT_NOW)}>{get(paymentErrorVerbiagesDTO, 'paymentFailedCtaSecondary', ERROR_HANDLING_VERBIAGES.PAYMENT_FAILED_CTA_SECONDARY)}</p>
                        </div>
                    </div>)

                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        opelResponse: get(state.subscriptionPaymentReducer, 'opelResponse'),
        paymentStatusFromPubnub: get(state.subscriptionPaymentReducer, 'paymentStatusFromPubnub'),
        configResponse: get(state.headerDetails, "configResponse.data.config"),
        currentSubscription: get(state.subscriptionDetails, 'currentSubscription'),
        paymentThroughTSRes: get(state.subscriptionPaymentReducer, 'paymentThroughTSWallet'),
        balanceAmount: get(state?.subscriptionPaymentReducer, "balanceInfo.data.balanceQueryRespDTO"),
    };
};

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(
        {
            hideFooter,
            hideHeader,
            showMainLoader,
            showMainLoaderImmediate,
            hideMainLoader,
            getOpelResponse,
            openPopup,
            closePopup,
            fetchConfig,
            getCurrentSubscriptionInfo,
            setPaymentProgressState,
        },
        dispatch,
    ),
});

SubscriptionPaymentHandler.propTypes = {
    hideFooter: PropTypes.func,
    hideHeader: PropTypes.func,
    showMainLoaderImmediate: PropTypes.func,
    showMainLoader: PropTypes.func,
    hideMainLoader: PropTypes.func,
    getOpelResponse: PropTypes.func,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func,
    configResponse: PropTypes.object,
    opelResponse: PropTypes.object,
    history: PropTypes.object,
    fetchConfig: PropTypes.func,
    currentSubscription: PropTypes.object,
    paymentMethod: PropTypes.string.isRequired,
    getCurrentSubscriptionInfo: PropTypes.func,
    setPaymentProgressState: PropTypes.func,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SubscriptionPaymentHandler),
);