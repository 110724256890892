import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import {fireFooterClickEvent, getSubscribeText, handleRedirectionOnClick, getBottomMenuItems, getMyPlanText, subscribeClickEvent} from '@utils/common';
import {FOOTER_ITEMS} from '../constants';
import {closePopup, openPopup} from "@common/Modal/action";
import {openLoginPopup} from '@containers/Login/APIs/actions';
import './style.scss';
import get from "lodash/get";
import {getCurrentSubscriptionInfo} from "@containers/Subscription/APIs/action";
import {checkCurrentSubscription} from "@containers/Subscription/APIs/subscriptionCommon";
import {URL} from "@constants/routeConstants";
import { deleteKey } from '@utils/storage';
import { HEADERS_TRIGGER_POINT, LOCALSTORAGE } from '@utils/constants';
import { triggerChatFlag } from '@containers/HelpCenter/APIs/action';

class ImportantLinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            footerItems: [],
        };
    }

    componentDidMount = async () => {
        this.props.configResponse && this.footerItemsList();
    }

    componentDidUpdate = async (prevProps) => {
        if ((this.props.loggedStatus !== prevProps.loggedStatus) ||
            (this.props.currentSubscription !== prevProps.currentSubscription) ||
            (this.props.configResponse !== prevProps.configResponse)) {
            this.footerItemsList();
        }
    }

    footerItemsList = () => {
        const {currentSubscription, loggedStatus} = this.props;
        let subscribeText = getSubscribeText();
        let {terms, privacy} = getBottomMenuItems();

        FOOTER_ITEMS[0].displayName = subscribeText;
        FOOTER_ITEMS[1].displayName = privacy;
        FOOTER_ITEMS[2].displayName = terms;

        if (loggedStatus && !checkCurrentSubscription(currentSubscription)) {
            FOOTER_ITEMS?.forEach(i => {
                if (i.displayName === subscribeText) {
                    i.displayName = getMyPlanText();
                    i.linkToRedirect = `${URL.SUBSCRIPTION}`;
                }
            })
        } else if (!loggedStatus) {
            if (FOOTER_ITEMS[0].displayName === getMyPlanText()) {
                FOOTER_ITEMS[0].displayName = subscribeText;
                FOOTER_ITEMS[0].linkToRedirect = `${URL.SUBSCRIPTION}`;
            }
        }

        this.setState({
            footerItems: FOOTER_ITEMS,
        });
    }

    menuItemClick = async (item) => {
        fireFooterClickEvent(item?.name);
        const { history, openPopup, closePopup, openLoginPopup, triggerChatFlag } = this.props;
        subscribeClickEvent(item);
        item = {...item, triggerPoint: HEADERS_TRIGGER_POINT.FOOTER_SUBSCRIBE}
        await handleRedirectionOnClick(item, history, openPopup, closePopup, openLoginPopup);
        if(item.linkToRedirect === URL.HELP_CENTER) {
            // deleteKey(LOCALSTORAGE.PRO_ACTIVE_CHAT_TRIGGER_COUNT);
            window.sessionStorage.removeItem(LOCALSTORAGE.PRO_ACTIVE_CHAT_TRIGGER_COUNT)
            triggerChatFlag({
                location: "",
                value: false,
            });
        }
    }

    render() {
        const {footerItems} = this.state;
        return (
            <div className="important-links">
                {footerItems && footerItems.length > 0 && <ul>
                    {
                        footerItems.map((item, index) => {
                            return (<li
                                onClick={() => this.menuItemClick(item)}
                                key={index}
                            >
                                <div>
                                    {item.displayName}
                                </div>
                            </li>)
                        })
                    }
                </ul>}
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    loggedStatus: state.commonContent.loggedStatus,
    currentSubscription: get(state.subscriptionDetails, 'currentSubscription.data'),
    configResponse: get(state.headerDetails, 'configResponse.data.config')
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({
            openPopup,
            closePopup,
            openLoginPopup,
            getCurrentSubscriptionInfo,
            triggerChatFlag
        }, dispatch),
    }
};


ImportantLinks.propTypes = {
    history: PropTypes.object,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func,
    openLoginPopup: PropTypes.func,
    loggedStatus: PropTypes.bool,
    currentSubscription: PropTypes.object,
    getCurrentSubscriptionInfo: PropTypes.func,
    configResponse: PropTypes.object,
}


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(ImportantLinks);